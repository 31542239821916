import React, { ReactNode } from "react"
import { RichTextContent } from "_types/RichText"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { Image } from "../image/Image"
import { ImageBuilder } from "_types/AssetsImage"
import { ButtonParser } from "components/button/ButtonParser"
import { ButtonBuilder } from "_types/Button"

interface HeroContentProps {
  heading?: string
  subHeading?: RichTextContent
  buttons?: ButtonBuilder[]
  secondaryImage?: ImageBuilder
  children?: ReactNode
  baseClassName:
    | "fr-hero__banner-content-with-image"
    | "fr-hero__banner-content"
    | "ppc-hero__ppc-banner-content"
}

export const HeroContent = ({
  heading,
  subHeading,
  buttons,
  secondaryImage,
  baseClassName,
  children,
}: HeroContentProps) => {
  return (
    <div className={`${baseClassName}__inner fr-container`}>
      {heading && <h1 className={`${baseClassName}__heading`}>{heading}</h1>}
      {subHeading && subHeading.value !== "<p><br></p>" && (
        <RichTextElement value={subHeading.value} />
      )}
      {buttons && buttons?.length > 0 && (
        <div className={`${baseClassName}__buttons`}>
          <ButtonParser
            buttons={buttons}
            primaryColorVariant="solid-secondary"
            secondaryColorVariant="outline-cta-ghost"
          />
        </div>
      )}
      {children}
      {secondaryImage && (
        <div className={`${baseClassName}__secondary-image`}>
          <Image image={secondaryImage} width={secondaryImage.width} />
        </div>
      )}
    </div>
  )
}

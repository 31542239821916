import React from "react"
import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import { HeroTab } from "../hero-tab"
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io"
import { Tabs } from "../../tabs/Tabs"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { TabbedHeroContentSlider } from "./TabbedHeroContentSlider"
import { BackgroundVideo } from "components/background-video"

interface TabbedHeroBannerContentProps {
  contentClassName: string
  handleActiveTabContent: (activeTabContent: HeroTab) => JSX.Element
  handleGetHeroBgImageUrl: (
    activeTabContent?: HeroTab
  ) => Record<string, string>
  hasBackgroundImage: boolean
}

export const TabbedHeroBannerContent = ({
  contentClassName,
  handleActiveTabContent,
  handleGetHeroBgImageUrl,
  hasBackgroundImage,
}: TabbedHeroBannerContentProps) => {
  const { size } = useWindowDimensions()
  const { activeItem } = useTabbedContainer<HeroTab>()
  const { imageBannerColorOverlay } = useColorAreaSettings()
  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundOverlayBefore`
    : ""

  return (
    <section
      className={`fr-hero ${overrideBackground}`}
      style={handleGetHeroBgImageUrl(activeItem)}
    >
      <BackgroundVideo
        hasBackgroundImage={hasBackgroundImage}
        componentName="fr-hero"
        url={activeItem.elements?.video_background_url?.value}
      >
        <div className={`${contentClassName} fr-container`}>
          {size >= 769 && (
            <>
              <Tabs
                className="fr-hero-tabs-controller"
                previousIcon={IoMdArrowDropleft}
                nextIcon={IoMdArrowDropright}
              />
              {activeItem && handleActiveTabContent(activeItem)}
            </>
          )}
          {size > 0 && size <= 768 && <TabbedHeroContentSlider />}
        </div>
      </BackgroundVideo>
    </section>
  )
}

import React, { useCallback } from "react"
import { graphql } from "gatsby"
import "../hero.module.scss"
import { getBackgroundImageUrlByBreakpoint } from "assets/helper/getBackgroundImageUrlByBreakpoint"
import { HeroContent } from "../HeroContent"
import { CmsAssetImage } from "_types/AssetsImage"
import { TabbedHeroBannerContent } from "./TabbedHeroBannerContent"
import { HeroTab } from "../hero-tab"
import { TabbedContainer } from "../../tabs/TabbedContainer"
import { BackgroundVideoPlayButton } from "components/background-video/BackgroundVideoPlayButton"

interface TabbedHeroBannerProps {
  banner: {
    background_image?: CmsAssetImage
    secondary_image?: CmsAssetImage
    hero_tabs: {
      modular_content: HeroTab[]
    }
  }
  customTrackingNumber?: string
}

export const TabbedHeroBanner = ({ banner }: TabbedHeroBannerProps) => {
  const { background_image, secondary_image, hero_tabs } = banner

  const contentClassName =
    secondary_image && secondary_image?.value?.length > 0
      ? "fr-hero__banner-content-with-image"
      : "fr-hero__banner-content"

  const getActiveTabContent = useCallback(
    (activeTabContent: HeroTab) => {
      const {
        heading,
        sub_heading,
        buttons,
        secondary_image_override,
        video_background_url,
      } = activeTabContent?.elements
      return (
        <>
          <HeroContent
            heading={heading?.value}
            subHeading={sub_heading}
            buttons={buttons?.value}
            secondaryImage={
              secondary_image_override?.value[0] || secondary_image?.value[0]
            }
            baseClassName={contentClassName}
          />

          {video_background_url?.value && <BackgroundVideoPlayButton />}
        </>
      )
    },
    [secondary_image]
  )

  const handleGetHeroBgImageUrl = useCallback(
    (activeTabContent?: HeroTab) => {
      if (!activeTabContent) return {}
      const heroBackgroundImageUrl =
        activeTabContent?.elements?.background_image_override?.value[0]?.url ||
        background_image?.value[0].url

      const backgroundImageCSSVariable = heroBackgroundImageUrl
        ? getBackgroundImageUrlByBreakpoint({
            variableName: "hero-background-image",
            imageUrl: heroBackgroundImageUrl,
            breakpoints: {
              mobile: {
                width: 768,
              },
            },
          })
        : {}

      return backgroundImageCSSVariable
    },
    [background_image]
  )

  const defaultBackgroundImageUrl = background_image?.value[0]?.url
  const hasDefaultBackgroundImage = !!defaultBackgroundImageUrl

  const hasBackgroundImage =
    hero_tabs.modular_content.some(({ elements }) => {
      const backgroundImageOverrideUrl =
        elements.background_image_override?.value[0]?.url
      const hasBackgroundImageOverride = !!backgroundImageOverrideUrl
      return hasBackgroundImageOverride
    }) || hasDefaultBackgroundImage

  return (
    <TabbedContainer allContent={hero_tabs.modular_content}>
      <TabbedHeroBannerContent
        contentClassName={contentClassName}
        handleGetHeroBgImageUrl={handleGetHeroBgImageUrl}
        handleActiveTabContent={getActiveTabContent}
        hasBackgroundImage={hasBackgroundImage}
      />
    </TabbedContainer>
  )
}

export const tabbedHeroFragment = graphql`
  fragment TabbedHeroBannerFragment on kontent_item_tabbed_hero {
    id
    elements {
      hero_tabs {
        modular_content {
          ...HeroTabFragment
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      video_background_url {
        value
      }
    }
  }
`

export const heroTabFragment = graphql`
  fragment HeroTabFragment on kontent_item_hero_tab {
    id
    elements {
      tab_title {
        value
      }
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      tagline {
        value
      }
      background_image_override {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image_override {
        value {
          ...KenticoAssetElementFragment
        }
      }
      buttons {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
      components_override {
        modular_content{
          ...HighlightGroupFragment
          ...LogoSliderFragment
          ...CouponFragment
        }
      }
    }
  }
`

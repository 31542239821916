import React from "react"
import { useMediaQuery } from "hooks/useMediaQuery"
import { IoPauseSharp } from "react-icons/io5"
import { PlayIcon } from "./assets/icons"
import { useBackgroundVideo } from "./hooks/useBackgroundVideo"

export const BackgroundVideoPlayButton = () => {
  // This checks if the browser option to reduce motion is selected for accessibility reasons (e.g.,people with vestibular disorders and epilepsy)
  const prefersReducedMotion = useMediaQuery("(prefers-reduced-motion: reduce)")

  // Prevents Background Video Button to be added (same as video)
  // if browser option to reduce motion is selected
  // This happens before useBackgroundVideo because the BackgroundVideContext won't exist if one of these conditions is met
  if (prefersReducedMotion) return <></>

  const {
    videoRef,
    playedAtLeastOnce,
    handlePlayedAtLeastOnce,
    componentName,
    playerState,
    handlePlayerState,
  } = useBackgroundVideo()

  const ARIA_LABELS = {
    playing: "Play Background Animation",
    paused: "Pause Background Animation",
  }

  const handlePauseVideo = () => {
    if (!videoRef.current) return
    videoRef.current.pause()
    handlePlayerState("paused")
  }

  const handlePlayVideo = () => {
    if (!videoRef.current) return
    videoRef.current.play()
    handlePlayerState("playing")

    if (!playedAtLeastOnce) {
      handlePlayedAtLeastOnce()
    }
  }

  const isVideoPaused = playerState === "paused"

  return (
    <button
      className={`fr-background-video__button fr-background-video__button--${componentName}`}
      aria-label={
        isVideoPaused ? ARIA_LABELS["paused"] : ARIA_LABELS["playing"]
      }
      onClick={isVideoPaused ? handlePlayVideo : handlePauseVideo}
    >
      <span className="fr-hero-background-video__button-label">
        {isVideoPaused ? "Play " : "Pause "}
        Video
      </span>
      <span className="fr-background-video__button-icons">
        {isVideoPaused ? (
          <PlayIcon className="fr-background-video__button-icon--play" />
        ) : (
          <IoPauseSharp className="fr-background-video__button-icon--pause" />
        )}
      </span>
    </button>
  )
}
